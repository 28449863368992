import React from 'react'

import Layout from '../../layout/BasicPage'
import Link from '../../components/Link'
import Section from '../../components/Section'
import SEO from '../../components/SEO'

import CEOInvestorConf from '../../_img/ceo-and-investor-conference.png'
import NEAmericaInvestorAndPartnership from '../../_img/east-n-america-investor-and-partnering.jpg'
import PEGSBostonConfAndExpo from '../../_img/pegs-boston-conference-and-expo.jpg'
import BaltimorePitchEvent from '../../_img/innovator-pitch-event.jpg'
import LSXWorldConference from '../../_img/lsx-world-conference-london.png'
import BioInternationalConvention from '../../_img/bio-international-convention-boston.png'
import BiotechCEOSummit from '../../_img/biotech-ceo-summit.png'
import AWSDemoDay from '../../_img/aws_demo_day.png'
import GoogleCloudNext23 from '../../_img/google-cloud-next-23.png'
import MedInvest from '../../_img/med-invest.png'
import SITC2023 from '../../_img/event-sitc-2023.jpg'
import BioEurope2023 from '../../_img/event-bio-europe-2023.jpg'
import PEGSEurope2023 from '../../_img/event-pegs-europe-2023.jpg'

import './events.scss'

const Events = ({ location }) => {
	const today = new Date()

	const { upcomingEvents, pastEvents } = allEvents.reduce(
		(acc, event) => {
			const eventDate = new Date(event.end)

			if (eventDate.getTime() > today.getTime()) {
				return {
					...acc,
					upcomingEvents: [...acc.upcomingEvents, event],
				}
			} else {
				return { ...acc, pastEvents: [...acc.pastEvents, event] }
			}
		},
		{ upcomingEvents: [], pastEvents: [] }
	)

	return (
		<Layout>
			<SEO title='Press' href={location.href} />
			<Section className='upcoming-events' title='Upcoming Events'>
				<ul>
					{upcomingEvents.map((event) => {
						return (
							<li className='event'>
								<img
									className='event-image'
									src={event.image}
								/>
								<div className='event-details'>
									<div className='event-details-date'>
										{getPrintableDate(
											event.start,
											event.end
										)}
									</div>
									<div className='event-details-title'>
										{event.title}
									</div>
									{event.url && (
										<div className='event-details-link'>
											(
											<Link href={event.url}>
												{event.url}
											</Link>
											)
										</div>
									)}
								</div>
							</li>
						)
					})}
				</ul>
			</Section>
			<Section className='past-events' title='Past Events'>
				<ul>
					{pastEvents.map((event) => {
						return (
							<li className='event'>
								<div className='event-details'>
									<div className='event-details-date'>
										{getPrintableDate(
											event.start,
											event.end
										)}
									</div>
									<div className='event-details-title'>
										{event.title}
									</div>
									<div className='event-details-link'>
										(
										<Link href={event.url}>
											{event.url}
										</Link>
										)
									</div>
								</div>
							</li>
						)
					})}
				</ul>
			</Section>
		</Layout>
	)
}

export default Events

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const allEvents = [
	{
		title: 'CEO & Investor Conference',
		url: 'https://www.bio.org/events/bio-ceo-investor-conference',
		image: CEOInvestorConf,
		start: '2023-02-06T23:45:00.000Z',
		end: '2023-02-09T23:45:00.000Z',
	},
	{
		title: 'BioTools Innovator Pitch Event',
		url: 'https://biotoolsinnovator.org/',
		image: BaltimorePitchEvent,
		start: '3/28/2023',
		end: '3/28/2023',
	},
	{
		title: 'East North America Investor & Partnering Event 2023',
		url: 'https://www.pegsummit.com/',
		image: NEAmericaInvestorAndPartnership,
		start: '3/9/2023',
		end: '3/9/2023',
	},
	{
		title: 'PEGS Boston Conference & Expo',
		url: 'https://www.pegsummit.com/',
		image: PEGSBostonConfAndExpo,
		start: '5/15/2023',
		end: '5/19/2023',
	},
	{
		title: 'LSX World Congress, London',
		url: 'https://www.lsxleaders.com/world',
		image: LSXWorldConference,
		start: '5/3/2023',
		end: '5/4/2023',
	},
	{
		title: 'BIO International Convention, Boston',
		url: 'https://www.bio.org/events/bio-international-convention',
		image: BioInternationalConvention,
		start: '6/5/2023',
		end: '6/8/2023',
	},
	{
		title: 'Biotech CEO Summit, La Jolla Ca',
		url: 'https://www.biotechceosummit.com/',
		image: BiotechCEOSummit,
		start: '7/17/2023',
		end: '7/19/2023',
	},
	{
		title: 'AWS Generative AI Accelerator Program Demo Day',
		image: AWSDemoDay,
		start: '8/09/2023',
		end: '8/10/2023',
	},
	{
		title: 'Google Cloud Next 2023',
		url: 'https://cloud.withgoogle.com/next',
		image: GoogleCloudNext23,
		start: '8/29/2023',
		end: '8/31/2023',
	},
	{
		title: 'MedInvest MedTech, AI & Digital Health Conference, Silicon Valley',
		url: 'https://www.medinvestconferences.com',
		image: MedInvest,
		start: '10/03/2023',
		end: '10/04/2023',
	},
	{
		title: 'Society of Immunotherapy of Cancer 38th Annual Meeting (SITC 2023)',
		url: 'https://www.sitcancer.org/home',
		image: SITC2023,
		start: '11/01/2023',
		end: '11/05/2023',
	},
	{
		title: 'BIO-Europe, Munich',
		url: 'https://informaconnect.com/bioeurope/',
		image: BioEurope2023,
		start: '11/06/2023',
		end: '11/08/2023',
	},
	{
		title: 'PEGS Europe, Lisbon',
		url: 'https://www.pegsummiteurope.com/',
		image: PEGSEurope2023,
		start: '11/14/2023',
		end: '11/16/2023',
	},
	{
		title: 'JPM Healthcare Conference 2024',
		url: 'https://www.jpmorgan.com/about-us/events-conferences/health-care-conference',
		start: '1/8/2024',
		end: '1/11/2024',
	},
	{
		title: 'BIO CEO & Investor Conference 2024',
		url: 'https://bcic.bio.org/',
		start: '2/26/2024',
		end: '2/27/2024',
	},
	{
		title: 'HealthNext 2024',
		url: 'https://www.healthnextsummit.com/',
		start: '3/4/2024',
		end: '3/5/2024',
	},
	{
		title: 'BIO Europe Spring 2024',
		url: 'https://informaconnect.com/bioeurope-spring/',
		start: '3/17/2024',
		end: '3/20/2024',
	},
	{
		title: 'BIO Korea 2024',
		url: 'https://www.biokorea.org/index.asp',
		start: '5/8/2024',
		end: '5/10/2024',
	},
	{
		title: 'Biocom Converge 2024',
		url: 'https://www.biocom.org/conferences/converge-summit/',
		start: '5/21/2024',
		end: '5/22/2024',
	},
	{
		title: 'BIO 2024',
		url: 'https://convention.bio.org/about-bio',
		start: '6/3/2024',
		end: '6/7/2024',
	},
	{
		title: 'Biotech CEO Summit 2024',
		url: 'https://www.biotechceosummit.com/',
		start: '7/15/2024',
		end: '7/17/2024',
	},
]

function getPrintableDate(start, end) {
	const startDate = new Date(start)
	const endDate = new Date(end)

	if (startDate.getDate() === endDate.getDate()) {
		return `${endDate.getDate()} ${
			months[endDate.getMonth()]
		} ${endDate.getFullYear()}`
	}

	return `${startDate.getDate()}-${endDate.getDate()} ${
		months[endDate.getMonth()]
	} ${endDate.getFullYear()}`
}
